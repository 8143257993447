
 import request from './request.js'

 // 设备详情
 export function getservelist(data) {
     return request({
         url: "/web/device/details",
         method: "post",
         data: data,
     });
 }
//订单表列表
 export function getdetail(data) {
     return request({
         url: "/web/order/list",
         method: "post",
         data: data,
     });
 }
 //订单表详细
 export function getfind(data) {
    return request({
        url: "/web/order/find",
        method: "post",
        data: data,
    });
}
 // 订单修改
 export function getreginter(data) {
    return request({
        url: "/web/order/edit",
        method: "post",
        data: data,
    });
}
 // 订单详情修改
 export function getorderreginter(data) {
    return request({
        url: "/web/order_info/edit",
        method: "post",
        data: data,
    });
}
//  订单详情列表
 export function getorderdetail(data) {
    return request({
        url: "/web/order_info/list",
        method: "post",
        data: data,
    });
}
//  订单详情详细
export function getorderfind(data) {
    return request({
        url: "/web/order_info/find",
        method: "post",
        data: data,
    });
}
//  设备
export function getserach(data) {
    return request({
        url: "/web/device/list",
        method: "post",
        data: data,
    });
}
//  收藏
export function getCollection(data) {
    return request({
        url: "/web/consumer_collect/save",
        method: "post",
        data: data,
    });
}
//  订单详情
export function getuserorder(data) {
    return request({
        url: "/web/user/order/detail",
        method: "post",
        data: data,
    });
}
//  修改
export function getmodify(data) {
    return request({
        url: "/web/order_modified/sava",
        method: "post",
        data: data,
    });
}
// 子订单详情
export function getOrderInfo(data) {
    return request({
        url: "/web/user/orderinfo",
        method: "post",
        data: data,
    });
}
//立即申请
export function apply(data) {
    return request({
        url: "/web/order_modified/save",
        method: "post",
        data: data,
    });
}
//判断登录
export function regirer(data) {
    return request({
        url: "/web/user/info",
        method: "post",
        data: data,
    });
}
//子订单详情
export function getchildren(data) {
    return request({
        url: "/web/user/orderinfo ",
        method: "post",
        data: data,
    });
}
//删除收藏
export function getdelete(data) {
    return request({
        url: "/web/consumer_collect/delete",
        method: "post",
        data: data,
    });
}
//收藏列表
export function getcollect(data) {
    return request({
        url: "/web/consumer_collect/list",
        method: "post",
        data: data,
    });
}
//删除订单
export function getorderdelete(data) {
    return request({
        url: "/web/order/cancel",
        method: "post",
        data: data,
    });
}
